<template>
  <div class="sponsorForm">
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          name="title"
          v-model="sponsor.title"
          placeholder="Title for Sponsor"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="title">Image:</label>
        <input
          type="text"
          name="image"
          v-model="sponsor.image"
          placeholder="Image for Sponsor"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="title">Link:</label>
        <input
          type="text"
          name="link"
          v-model="sponsor.link"
          placeholder="Link for Sponsor"
          class="form-control"
        />
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import SponsorService from "../../services/SponsorService";

export default {
  data: function () {
    return {
      sponsor: {
        title: "",
        link: "",
        image: "",
      },
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.id)) {
      SponsorService.findById(this.$route.params.id).then((response) => {
        let data = response.data;
        this.sponsor.title = data.title;
        this.sponsor.link = data.link;
        this.sponsor.image = data.image;
      });
    }
  },

  methods: {
    saveChanges: function () {
      let sponsorRow = {
        title: this.sponsor.title,
        link: this.sponsor.link,
        image: this.sponsor.image,
      };

      if (!isNaN(this.$route.params.id)) {
        SponsorService.update(this.$route.params.id, sponsorRow).then(
          (response) => {
            alert("Sponsor Updated");
          }
        );
      } else if (this.$route.params.id == "add") {
        SponsorService.create(sponsorRow).then((response) => {
          alert("Sponsor created.");
        });
      }
    },
  },
};
</script>
